import { ExhibitExperience } from '../ExhibitRenderer';
import P5 from 'p5';
import p5 from 'p5';
import { Interface } from 'readline';
import { ShapeUtils } from 'three';
import { Point } from 'google-map-react';
import { ExhibitStorage, ExhibitStoragePrototype } from './ExhibitStorage';


export class NightlifeMapExperience implements ExhibitExperience {
    p5Container: HTMLDivElement
    
    constructor (p5Container: HTMLDivElement) {
        this.p5Container = p5Container;

        if (this.exhibitStorage.exhibit_one == undefined) {
            this.exhibitStorage.exhibit_one = false
            this.exhibitStorage.exhibit_two = false
            this.exhibitStorage.exhibit_three = false
        }

        console.log(this.exhibitStorage.exhibit_one)
        
        type Point2D = {
            x: number,
            y: number
        }


        class ExhibitFloor {
            p5: P5
            img: p5.Image
            showBoothImage: boolean = false
            pinLocations: Array<Point2D> = [{x: 0,y: 0}]
            pinImage: p5.Image
            boothImage: p5.Image

            constructor(imgURL: string, p5: P5, showBoothImage: boolean = false, pinLocations: Array<Point2D> = []) {
                this.p5 = p5
                this.img = p5.loadImage(imgURL);
                this.showBoothImage = showBoothImage;
                this.pinLocations = pinLocations;
                this.pinImage = p5.loadImage("/img/nightlife/nightlife_qr_pin.png");
                this.boothImage = p5.loadImage("/logo192.png");
            }

            draw = (position: Point2D) => {
                this.p5.image(
                    this.img,
                    position.x,
                    position.y,
                    this.img.width * 0.7,
                    this.img.height * 0.7
                )
                for (let i=0; i < this.pinLocations.length; i++) {
                    this.p5.image(
                        this.pinImage,
                        position.x + this.pinLocations[i].x,
                        position.y + this.pinLocations[i].y,
                        this.pinImage.width * 0.15,
                        this.pinImage.height * 0.15
                    )
                }
                if (this.showBoothImage) {
                    this.p5.image(
                        this.boothImage,
                        position.x + 265,
                        position.y + 170,
                        this.boothImage.width * 0.5,
                        this.boothImage.height * 0.5
                    )
                }
            }
        }

        class SolvedCounter {
            p5: P5
            exhibitStorage: ExhibitStoragePrototype
            solved: number
            

            constructor(p5: P5, exhibitStorage: ExhibitStoragePrototype) {
                this.p5 = p5
                this.exhibitStorage = exhibitStorage
                this.solved = (this.exhibitStorage.exhibit_one ? 1 : 0) + 
                    (this.exhibitStorage.exhibit_two ? 1 : 0) + 
                    (this.exhibitStorage.exhibit_three ? 1 : 0)
                console.log(`--> ${this.exhibitStorage.exhibit_one} ${this.exhibitStorage.exhibit_two} ${this.exhibitStorage.exhibit_three}`)
            }

            draw = (position: Point2D) => {
                let total = 3
                this.p5.rect(position.x, position.y, 150, 30)
                this.p5.text(`Solved Puzzles: ${this.solved}/${total}`, position.x + 5, position.y + 19)
            }
        }

        class Button {
            p5: P5
            id: number
            diameter: number = 30
            padding: number = 10
            position: Point2D
            label: string

            constructor(p5: P5, id: number, label: string) {
                this.p5 = p5
                this.id = id
                this.label = label

                this.position = { 
                    x: this.id * this.diameter + (
                        (this.id + 1) * this.padding
                    ) + this.padding,
                    y: this.padding * 2
                }
            }

            checkClicked = (position: Point2D):boolean => {
                let x = Math.abs(position.x - this.position.x)
                let y = Math.abs(position.y - this.position.y)
                if (x < this.diameter / 2.0 && y < this.diameter / 2.0) {
                    return true
                }
                return false
            }

            draw = () => {
                this.p5.circle(
                    this.position.x,
                    this.position.y,
                    this.diameter
                )
                this.p5.text(this.label, this.position.x - 3, this.position.y + 5)
            }
        }

        class AcademyOfSciencesMap {
            p5: P5
            // img: p5.Image
            position: Point2D
            floors: Array<ExhibitFloor>
            floorButtons: Array<Button> = Array<Button>()
            currentFloor: number = 1
            solvedCounter: SolvedCounter
            
            constructor(p5: P5, exhibitStorage: ExhibitStoragePrototype) {
                this.p5 = p5
                this.position = { x: 0, y: 0 }
                this.solvedCounter = new SolvedCounter(p5, exhibitStorage)
                this.floors = [
                    new ExhibitFloor("/img/nightlife/01_floor_b.jpg", p5, false, [{x: 530,y: 400}]),
                    new ExhibitFloor("/img/nightlife/02_floor_1.jpg", p5, true, [{x: 575,y: 140}]),
                    new ExhibitFloor("/img/nightlife/03_floor_2.jpg", p5, false),
                    new ExhibitFloor("/img/nightlife/04_floor_3.jpg", p5, false),
                    new ExhibitFloor("/img/nightlife/05_floor_4.jpg", p5, false, [{x: 350,y: 140}])
                ]
                let labels = ["B", "1", "2", "3", "4"]
                let i = 0
                this.floors.forEach((floor) => {
                    this.floorButtons.push(
                        new Button(this.p5, i, labels[i])
                    )
                    i++
                })
            }

            selectFloor = (id: number) => {
                this.currentFloor = id
            }

            clickedButton = (position: Point2D):(Button | undefined) => {
                for (var i = 0; i < this.floorButtons.length; i++) {
                    let button = this.floorButtons[i]
                    if (button.checkClicked(position)) {
                        return button
                    }
                }
                return undefined
            }

            draw = () => {
                this.floors[this.currentFloor].draw(this.position)
                this.floorButtons.forEach((button) => {
                    button.draw()
                })
                let lastButton = this.floorButtons[this.floorButtons.length - 1]
                this.solvedCounter.draw({
                    x: lastButton.position.x + 30,
                    y: 5
                })
            }
        }

        //Pardon the duplicate, temporary until this can be made into a template
        class ChabotMap {
            p5: P5
            // img: p5.Image
            position: Point2D
            floors: Array<ExhibitFloor>
            floorButtons: Array<Button> = Array<Button>()
            currentFloor: number = 0
            solvedCounter: SolvedCounter
            
            constructor(p5: P5, exhibitStorage: ExhibitStoragePrototype) {
                this.p5 = p5
                this.position = { x: 0, y: 0 }
                this.solvedCounter = new SolvedCounter(p5, exhibitStorage)
                this.floors = [
                    new ExhibitFloor("/img/nightlife/chabot_floor_1.jpg", p5, false),
                    new ExhibitFloor("/img/nightlife/chabot_floor_2.jpg", p5, true, [{x: 225,y: 270}]),
                    new ExhibitFloor("/img/nightlife/chabot_floor_3.jpg", p5, false, [{x: 250,y: 620}, {x: 220,y: 220}])
                ]
                let labels = ["1", "2", "3"]
                let i = 0
                this.floors.forEach((floor) => {
                    this.floorButtons.push(
                        new Button(this.p5, i, labels[i])
                    )
                    i++
                })
            }

            selectFloor = (id: number) => {
                this.currentFloor = id
            }

            clickedButton = (position: Point2D):(Button | undefined) => {
                for (var i = 0; i < this.floorButtons.length; i++) {
                    let button = this.floorButtons[i]
                    if (button.checkClicked(position)) {
                        return button
                    }
                }
                return undefined
            }

            draw = () => {
                this.floors[this.currentFloor].draw(this.position)
                this.floorButtons.forEach((button) => {
                    button.draw()
                })
                let lastButton = this.floorButtons[this.floorButtons.length - 1]
                this.solvedCounter.draw({
                    x: lastButton.position.x + 30,
                    y: 5
                })
            }
        }

        const sketch = (p5: P5) => {
            let isMouse:boolean = false;
            let startPointer:TouchEvent | MouseEvent | undefined;
            let exhibitMap: AcademyOfSciencesMap
            let startOffset: Point2D
            let buttonClicked: Button | undefined

            p5.preload = () => {
                exhibitMap = new AcademyOfSciencesMap(p5, this.exhibitStorage);
                // exhibitMap = new ChabotMap(p5, this.exhibitStorage);
            }

            p5.setup = () => {
                const canvas = p5.createCanvas(p5.windowWidth, p5.windowWidth);
                canvas.parent("p5-app");
                document.body.style.position = "fixed"
                document.body.style.overflow = "hidden"
                document.body.style.width = "100%"
                document.body.style.height = "100%"
                p5.windowResized()
            }

            p5.mouseDragged = (event) => {
                dragged(event)
            }

            let dragged = (event) => {
                if (buttonClicked != undefined || startPointer == undefined) {
                    return
                }
                let newPoint:Point2D
                if (isMouse) {
                    let startMouseTouch = startPointer as MouseEvent
                    let newMouseTouch = event as MouseEvent

                    newPoint = { 
                        x: newMouseTouch.clientX - startMouseTouch.clientX + startOffset.x,
                        y: newMouseTouch.clientY - startMouseTouch.clientY + startOffset.y
                    }
                } else {
                    let startMouseTouch = startPointer as TouchEvent
                    let newMouseTouch = event as TouchEvent

                    newPoint = { 
                        x: newMouseTouch.touches[0].clientX - startMouseTouch.touches[0].clientX + startOffset.x,
                        y: newMouseTouch.touches[0].clientY - startMouseTouch.touches[0].clientY + startOffset.y
                    }

                }

                exhibitMap.position = newPoint
            }

            let pressed = (event) => {
                startOffset = exhibitMap.position

                if (event instanceof MouseEvent) {
                    startPointer = event as MouseEvent;
                    isMouse = true;
                } else {
                    startPointer = event as TouchEvent;
                    isMouse = false;
                }

                let touchPosition:Point2D
                if (isMouse) {
                    // let startMouseTouch = startPointer as MouseEvent
                    let newTouch = event as MouseEvent
                    touchPosition = {
                        x: newTouch.clientX,
                        y: newTouch.clientY
                    }
                } else {
                    // let startTouch = startPointer as TouchEvent
                    let newTouch = event as TouchEvent
                    touchPosition = {
                        x: newTouch.touches[0].clientX,
                        y: newTouch.touches[0].clientY
                    }
                }

                buttonClicked = exhibitMap.clickedButton(touchPosition)
                if (buttonClicked != undefined) {
                    exhibitMap.selectFloor((buttonClicked as Button).id)
                    startPointer = undefined
                    buttonClicked = undefined
                }
            }

            let released = (event) => {
                let newClicked:Button
                if (isMouse) {
                    // let startMouseTouch = startPointer as MouseEvent
                    let newTouch = event as MouseEvent

                    newClicked = exhibitMap.clickedButton({
                        x: newTouch.clientX,
                        y: newTouch.clientY
                    })!

                    if (buttonClicked == newClicked && buttonClicked != undefined) {
                        exhibitMap.selectFloor((buttonClicked as Button).id)
                    }
                    buttonClicked = undefined
                } else {
                    // let newTouch = event as TouchEvent
                    // // newClicked = aosMap.clickedButton({
                    // //     x: newTouch.touches[0].clientX,
                    // //     y: newTouch.touches[0].clientY
                    // // })!
                }

            }

            p5.mousePressed = (event) => {
                pressed(event)
            }

            p5.mouseReleased = (event) => {
                released(event)
            }

            p5.touchStarted = (event) => {
                pressed(event)
            }

            p5.touchEnded = (event) => {
                released(event)
            }

            p5.touchMoved = (event) => {
                dragged(event)
            }
        
            p5.draw = () => {
                p5.background("white")
                exhibitMap.draw();
                
            };
        
            p5.windowResized = (event?) => {
                p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
                
            }
        }
        
        var myP5 = new P5(sketch);
    }

    exhibitStorage: ExhibitStoragePrototype = ExhibitStorage
}