import { Box, Grid } from "@mui/material";
import { Sectional } from "layout/Sectional";
import Splash from "layout/Splash";
import React from "react";
import { EventCalendarProperties } from "widgets/Types/Types";



export const SpaceWidget: React.FC = () => {

    return (
        <>
            <Sectional>
                
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box component="img" sx={{ "maxWidth": "100%"}} src="img/stock/space_rental/bottom_floor.png"></Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <h3>It really <i>is</i> all fun and games.</h3>
                        <p>
                            First and foremost, video games are meant to be played and enjoyed. Our <a href="/exhibits">all-playable exhibits</a> offer visitors a way to step through decades of history, directly interacting with digital experiences from the past. Whether it's nostalgia, a way to play a game we haven't played before, or an outing with some friends, we encourage you to have fun! And when we teach our classes, this space becomes a playable laboratory where students study interactive design through the ages.
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box component="img" sx={{ "maxWidth": "100%"}} src="img/stock/space_rental/top_floor.png"></Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <h3>Community Space and Collection</h3>
                        <p>
                            Upstairs, we host our volunteer meetings, <a href="/made-forum">our speaker series (MADE Forum)</a>, <a href="/classes">our education programs (MADE Classroom)</a>, and our Indie Gamedev Coworking. Across the stairs, you'll find <a href="/collection-storage">our collection</a>, a 1000 square foot section filled to the brink with artifacts spanning across all areas of game development.
                        </p>
                    </Grid>
                </Grid>
            </Sectional>
        </>
    );
}