import React from "react"
import { Box, Container, Divider, Grid, createTheme, useMediaQuery } from "@mui/material"
import { getDesignTokens } from "AppGlobals";

export const FooterWidget = () => {

    const socialMediaLinks = [
        {
            href: 'https://twitter.com/TheMADE',
            icon: '/img/icon/Twitter_Logo_Blue.svg',
            alt: 'Twitter: @TheMADE',
            text: 'Twitter @TheMADE',
            inverts: false,
        },
        {
            href: 'https://www.instagram.com/mademuseum/',
            icon: '/img/icon/instagram.svg',
            alt: 'Instagram: Made Museum',
            text: 'Instagram @mademuseum ',
            inverts: false,
        },
        {
            href: 'https://www.facebook.com/themuseumofartanddigitalentertainment/',
            icon: '/img/icon/facebook_logo.svg',
            alt: 'Facebook: The MADE',
            text: 'The MADE on Facebook',
            inverts: false,
        },
        {
            href: 'https://www.youtube.com/@TheMADEOak',
            icon: '/img/icon/youtube_logo.svg',
            alt: 'Youtube: The MADE',
            text: '@TheMADEOak on Youtube',
            inverts: false,
        },
        {
            href: 'https://www.twitch.tv/themadeoakland',
            icon: '/img/icon/twitch.svg',
            alt: 'TheMADEOakland on Twitch.TV',
            text: 'TheMADEOakland on Twitch.TV',
            inverts: false,
        },
        {
            href: 'https://www.patreon.com/themade',
            icon: '/img/icon/patreon.svg',
            alt: 'Support The MADE Patreon',
            text: 'Support The MADE Patreon',
            inverts: false,
        },
        {
            href: '/podcast',
            icon: '/img/icon/podcast.svg',
            alt: 'MADE PODCAST',
            text: 'Our Podcast: The MADECast',
            inverts: true,
        },
        {
            href: 'https://discord.gg/tMVcswHFjh',
            icon: '/img/icon/discord_2024.svg',
            alt: 'Join our Discord Server',
            text: 'The MADE Discord Server',
            inverts: false,
        },
        {
            href: 'https://www.guidestar.org/profile/26-4570976',
            icon: '/img/icon/guidestar_seal_2024.svg',
            alt: 'Candid: The MADE',
            text: 'Candid Platinum Transparency',
            inverts: false,
        },
        {
            href: 'mailto:info@themade.org',
            icon: '/img/icon/email_letter.svg',
            alt: 'Email The MADE: info@themade.org',
            text: 'Email The MADE: info@themade.org',
            inverts: true,
        }
      ];
      

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
            prefersDarkMode ? "dark" : "light")
    ),
        [
            prefersDarkMode ? "dark" : "light"
        ]
    );

    let color = prefersDarkMode ? "0%" : "100%";
    let link_color = prefersDarkMode ? "white" : "black";

    return (
        <>
            <Divider />
            <Container>
                <h2 >Find us on Social Media</h2>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                        gap: '16px'
                    }}
                >
                    {socialMediaLinks.map((link, index) => (
                        <a
                            key={index}
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: link_color, textDecoration: 'none' }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '50px'
                                }}
                            >
                                <img
                                    style={{
                                        verticalAlign: 'middle',
                                        filter: link.inverts ? `invert(${color})` : 'none',
                                        paddingRight: '10px'
                                    }}
                                    width="50px"
                                    src={link.icon}
                                    alt={link.alt}
                                />
                                <h3>{link.text}</h3>
                            </Box>
                        </a>
                    ))}
                </Box>
                <br />
                <a
                    href="/"
                    style={{ color: link_color, textDecoration: 'none', textAlign: 'center' }}
                ><h3>TheMADE.org // 2009 - 2024</h3></a>

            </Container>
        </>
    );
};

/*
export const FooterWidget: React.FC = () => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
            prefersDarkMode ? "dark" : "light")
    ),
        [
            prefersDarkMode ? "dark" : "light"
        ]
    );

    let color = prefersDarkMode ? "0%" : "65%";
    let link_color = prefersDarkMode ? "white" : "black";
    

    return (
        <>
            <Divider></Divider>
            <Container>
                <h2>Find us on Social Media</h2>
                <Box sx={{ height: "50px" }}>
                    <a href="https://twitter.com/TheMADE" target="_blank" rel="noopener noreferrer" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", paddingRight: "10px" }} width="50px" src="/img/icon/Twitter_Logo_Blue.svg" alt="Twitter: @TheMADE" />
                            @TheMADE
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}>
                    <a href="mailto:info@themade.org" target="_blank" rel="noopener noreferrer" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", filter: "invert(" + color + ")", paddingRight: "10px" }} width="50px" src="/img/icon/envelope.svg" alt="info@themade.org" />
                            info@themade.org
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}>
                    <a href="https://www.instagram.com/mademuseum/" target="_blank" rel="noopener noreferrer" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", filter: "invert(" + color + ")", paddingRight: "10px" }} width="50px" src="/img/icon/instagram_logo.svg" alt="Instagram: Made Museum" />
                            mademuseum ig
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}>
                    <a href="https://www.facebook.com/themuseumofartanddigitalentertainment/" target="_blank" rel="noopener noreferrer" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", paddingRight: "10px" }} width="50px" src="/img/icon/facebook_logo.svg" alt="Facebook: The MADE" />
                            The MADE on FB
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}>
                    <a href="https://www.patreon.com/themade" target="_blank" rel="noopener noreferrer" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", filter: "invert(" + color + ")", paddingRight: "10px" }} width="50px" src="/img/icon/patreon.png" alt="MADE Patreon" />
                            MADE Patreon
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}>
                    <a href="/podcast" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", filter: "invert(" + color + ")", paddingRight: "10px" }} width="50px" src="/img/icon/podcast.svg" alt="MADE PODCAST" />
                            The MADECast (MADE Podcast)
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}>
                    <a href="https://www.youtube.com/@TheMADEOak" target="_blank" rel="noopener noreferrer" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", paddingRight: "10px" }} width="50px" src="/img/icon/youtube_logo.svg" alt="Youtube: The MADE" />
                            @TheMADEOak
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}>
                    <a href="https://www.twitch.tv/themadeoakland" target="_blank" rel="noopener noreferrer" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", filter: "invert(" + color + ")", paddingRight: "10px" }} width="50px" src="/img/icon/twitch_logo.svg" alt="Twitch: The MADE" />
                            The MADE Twitch Stream
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}>
                    <a href="https://www.guidestar.org/profile/26-4570976" target="_blank" rel="noopener noreferrer" style={{ color: link_color, textDecoration: 'none'  }}>
                        <h3>
                            <img style={{ verticalAlign: "middle", paddingRight: "10px" }} width="50px" src="/img/icon/guidestar_seal_2023.png" alt="Candid: The MADE" />
                            Candid Platinum Transparency
                        </h3>
                    </a>
                </Box>
                <Box sx={{ height: "50px" }}></Box>
            </Container>
        </>
    )
}
*/
