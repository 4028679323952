import React, { ReactNode, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { request } from 'http';


type MADEMenuItem = {
    title: string,
    children: MADEMenuItem[] | undefined,
    target: string | undefined,
    url: string | undefined
}

type TopMenuProperties = {
    bg: string,
    color: string
}

type PopoverProperties = {
    anchorElNav: null | HTMLElement,
    open: null | string,
    menuItems: null | MADEMenuItem[],
    handleCloseNavMenu: () => void,
    handleOpenListMenu: (event: React.MouseEvent<HTMLElement>) => void,
    SiteMap: any
}

type WideTopMenuProperties = {
    anchorElPage: null | HTMLElement,
    open: null | string,
    menuItems: null | MADEMenuItem[],
    handleClosePageMenu: () => void,
    handleOpenPageMenu: (event: React.MouseEvent<HTMLElement>) => void,
    SiteMap: any
}

type ToolbarIconProperties = {
    prefersDarkMode: boolean,
    handleOpenNavMenu: (event: React.MouseEvent<HTMLElement>) => void
}

const PopoverTopMenu: React.FC<PopoverProperties> = ({ anchorElNav, open, menuItems, handleCloseNavMenu, handleOpenListMenu, SiteMap }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const linkColor = prefersDarkMode ? "white" : "black";

    return (
        <Popover
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            anchorEl={null}
            sx={{ display: { xs: 'flex', md: 'none' } }}
        >
            <Button sx={{
                px: 3,
                pt: 2.5,
                pb: 2.5,
                align: 'right'
            }}
                onClick={handleCloseNavMenu}>
                <Close></Close>
            </Button>
            <List>
                {
                    SiteMap.map((page) => (
                        page.children === undefined ?
                            <ListItemButton key={page.title}>
                                <a
                                    href={page.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: linkColor, textDecoration: 'none' }}
                                >
                                    <Typography textAlign="center">{page.title}</Typography>
                                </a>


                            </ListItemButton>
                            :
                            <>
                                <ListItemButton
                                    key={page.title}
                                    id={page.title}
                                    alignItems="flex-start"
                                    onClick={page.children === undefined ? () => { } : handleOpenListMenu}
                                    sx={{
                                        px: 2.5,
                                        pt: 2.5,
                                        pb: 2.5
                                    }}
                                >
                                    <ListItemText
                                        primary={page.title}
                                        primaryTypographyProps={{
                                            fontSize: 15,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                        sx={{ my: 0 }}
                                    />
                                    <KeyboardArrowDown
                                        sx={{
                                            mr: -1,
                                            transform: open === page.title ? 'rotate(-180deg)' : 'rotate(0)',
                                            transition: '0.2s',
                                        }}
                                    />
                                </ListItemButton>
                                {page.title === open &&
                                    menuItems?.map((page) => (
                                        <ListItemButton
                                            key={page.title}
                                            sx={{ py: 0, minHeight: 32 }}
                                            component={Link} to={page.url as string}
                                        >
                                            <ListItemText
                                                primary={page.title}
                                                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }} />
                                        </ListItemButton>
                                    ))
                                }
                            </>
                    )
                    )
                }
            </List>
        </Popover>
    );
}

const WideTopMenu: React.FC<WideTopMenuProperties> = ({ anchorElPage, open, menuItems, handleClosePageMenu, handleOpenPageMenu, SiteMap }) => {
    return (
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {SiteMap.map((page) => (

                page.children === undefined ?
                    <Button
                        key={page.title}
                        id={page.title}
                        href={page.url}
                        sx={{ my: 2 }}
                        color="inherit">
                        {page.title}
                    </Button>
                    :
                    <Button
                        key={page.title}
                        id={page.title}
                        onClick={page.children === undefined ? () => { } : handleOpenPageMenu}
                        sx={{ my: 2, mx: 1 }}
                        endIcon={<KeyboardArrowDown
                            sx={{
                                mr: -1,
                                transform: open === page.title ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '0.2s',
                            }} />}
                        color="inherit"
                    >
                        {page.title}
                    </Button>
            ))}
            {
                menuItems === undefined ? <></> :
                    <Menu
                        sx={{ mt: '45px', display: { xs: 'none', md: 'flex' } }}
                        id="menu-appbar"
                        anchorEl={anchorElPage}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElPage)}
                        onClose={handleClosePageMenu}
                    >
                        {menuItems?.map((setting) => ( setting.target === "_blank" ?
                            <MenuItem key={setting.title}>
                                <a
                                    href={setting.url as string}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'black', textDecoration: 'none' }}
                                >
                                    <Typography textAlign="center">{setting.title}</Typography>
                                </a> 
                            </MenuItem>:
                            <MenuItem component={Link} to={setting.url as string} key={setting.title}>
                                <Typography textAlign="center">{setting.title}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
            }
        </Box>
    );
}

const ToolbarIcon: React.FC<ToolbarIconProperties> = ({ prefersDarkMode, handleOpenNavMenu }) => {
    return (
        <>
            <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    textDecoration: 'none',
                }}
            >
                <Box component="img"
                    sx={
                        {
                            height: 64,
                        }
                    }
                    alt="Your logo."
                    src={prefersDarkMode ? "/logo256d.png" : "/logo256.png"}>
                </Box>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
            <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    textDecoration: 'none',
                }}
            >
                <Box component="img"
                    sx={
                        {
                            height: 64,
                        }
                    }
                    alt="Your logo."
                    src={prefersDarkMode ? "/logo256d.png" : "/logo256.png"}>
                </Box>
            </Typography>
        </>
    );
}

export const AlertMessage: React.FC = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [alerts, setAlerts] = useState([]);

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Cache-Control', 'no-cache');
    requestHeaders.set('Pragma', 'no-cache');
    requestHeaders.set('Expires', '0');

    useEffect(() => {
        fetch("/globals/alerts.json", { 'headers': requestHeaders } )
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setAlerts(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setAlerts(error);
                }
            )
    }, [])

    const recAlerts = alerts["alerts"];
    if (recAlerts) {
        const now = Date.now();
        return (
            <Container sx={{ left: 0, right: 0, position: "absolute", marginTop: "80px", zIndex: 3 }} >
                {
                    recAlerts.map((alert) => (
                        Date.parse(alert.end_date) > now && Date.parse(alert.start_date) < now ?

                        <Alert sx={ { marginBottom: "20px" } } variant="filled" severity={ alert.severity }>
                            { alert.description }
                        </Alert> : <></>

                    ))
                }
            </Container>
        )
    } else {
        return <></>
    }
}

export const TopMenu: React.FC = () => {

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    // const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElPage, setAnchorElPage] = React.useState<null | HTMLElement>(null);
    const [menuItems, setMenuItems] = React.useState<null | MADEMenuItem[]>(null);

    const [open, setOpen] = React.useState<null | string>(null);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [navs, setNavs] = useState([]);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Cache-Control', 'no-cache');
    requestHeaders.set('Pragma', 'no-cache');
    requestHeaders.set('Expires', '0');
  
    useEffect(() => {
        fetch("/navigation/sitemap.json", { 'headers': requestHeaders })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setNavs(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    var SiteMap = navs["sitemap"];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElUser(event.currentTarget);
    // };

    const handleOpenPageMenu = (event: React.MouseEvent<HTMLElement>) => {
        const page = SiteMap.find((obj) => {
            return obj.title === event.currentTarget.id;
        });
        if (page.title === open) {
            setMenuItems(null);
            setOpen(null);
            return;
        }
        if (page != undefined) {
            setMenuItems(page.children);
            setOpen(page.title);
        } else {
            setMenuItems(null);
            setOpen(null);
        }

        setAnchorElPage(event.currentTarget);
    };

    const handleOpenListMenu = (event: React.MouseEvent<HTMLElement>) => {
        console.log(event.currentTarget.id);
        const page = SiteMap.find((obj) => {
            return obj.title === event.currentTarget.id;
        })
        if (page.title === open) {
            setMenuItems(null);
            setOpen(null);
            return;
        }
        if (page != undefined) {
            setMenuItems(page.children);
            setOpen(page.title);
        } else {
            setMenuItems(null);
            setOpen(null);
        }
    };

    const handleClosePageMenu = () => {
        setMenuItems(null);
        setOpen(null);
        setAnchorElPage(null);
    };

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    if (SiteMap) {
        return (
            <>
                <AlertMessage />
                <AppBar position="fixed" elevation={0} enableColorOnDark>
                    <PopoverTopMenu
                        anchorElNav={anchorElNav}
                        open={open}
                        menuItems={menuItems}
                        handleCloseNavMenu={handleCloseNavMenu}
                        handleOpenListMenu={handleOpenListMenu}
                        SiteMap={SiteMap} />
                    <Container maxWidth="lg">
                        <Toolbar disableGutters>
                            <ToolbarIcon prefersDarkMode={prefersDarkMode} handleOpenNavMenu={handleOpenNavMenu} />

                            <WideTopMenu
                                anchorElPage={anchorElPage}
                                open={open}
                                menuItems={menuItems}
                                handleClosePageMenu={handleClosePageMenu}
                                handleOpenPageMenu={handleOpenPageMenu}
                                SiteMap={SiteMap} />
                        </Toolbar>
                    </Container>
                </AppBar>
            </>

        )
    } else {
        return <></>
    }
}

// export default TopMenu;
