import React, { ReactNode } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { theme } from 'layout/Themes';
import { useMediaQuery } from '@mui/material';
import { getDesignTokens } from 'AppGlobals';


var headline = createTheme({
  typography: {
    fontFamily: [
      'Bungee Hairline',
    ].join(','),
    h1: {
      fontSize: '7rem',
      '@media (min-width:600px)': {
        fontSize: '5rem',
      },
      [theme.breakpoints.up('xs')]: {
        fontSize: '3.5rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '3.5rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '4rem',
      },
    }
  },
});

headline = responsiveFontSizes(headline);

type MegaSplashProperties = {
  smallImage: string,
  bigImage: string,
  children: ReactNode | undefined
}

export const MegaSplash: React.FC<MegaSplashProperties> = ({ smallImage, bigImage, children}) => {
  const scaledHeight = { xs: "700px", sm: "500px", md: "800px", lg: "800px" };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(
    getDesignTokens(
      prefersDarkMode ? "dark" : "light")
    ),
    [
      prefersDarkMode ? "dark" : "light"
    ]
  );
  
  return (
      <Box sx={{ 
        zIndex: "-999", 
        background: { 
          xs: 'url("' + smallImage + '")',
          sm: 'url("' + bigImage + '")',
          md: 'url("' + bigImage + '")',
        }, 
        backgroundSize: {
          xs: "auto 100%",
          sm: "cover",
          md: "cover",
          lg: "cover"
        },
        display: "block",
        height: scaledHeight
      }}>
        <Box sx={
          { 
            position: "absolute",
            height: scaledHeight,
            width: "100vw",
            zIndex: "200",
            display: "flex",
            alignItems: "center"
          }
        }>
          <Container maxWidth="lg">
            { children }
          </Container>
        </Box>
        <Box sx={{
          position: "absolute",
          height: scaledHeight,
          width: "100vw",
          backgroundColor: theme.palette.background.default,
          opacity: ".9",
          zIndex: "2"
        }
          }>
        </Box>
      </Box>
  )
}

export const MegaSplashTitle: React.FC<{ children: ReactNode | undefined}> = ({children}) => {
  return (
    <ThemeProvider theme={headline}>
      <Typography 
        sx={ { 
          ml: { xs: -.5, sm: -.5, md: -1.7 }, 
          display: { xs: 'none', sm: 'block' }
        } } 
        variant="h1"
      >
        { children }
      </Typography>
    </ThemeProvider>
  );
}

export const MegaSplashTitleSmall: React.FC<{ children: ReactNode | undefined}> = ({children}) => {
  return (
    <ThemeProvider theme={headline}>
      <Typography 
        sx={ { 
          ml: { xs: -.5, sm: -.5, md: -1.7 }, 
          display: { xs: 'inline', sm: 'none' }
        } } 
        variant="h1"
      >
        { children }
      </Typography>
    </ThemeProvider>
  );
}

export const MegaSplashSubtitle: React.FC<{ children: ReactNode | undefined}> = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h4" gutterBottom>
        { children }
      </Typography>
    </ThemeProvider>
  );
}
