import * as THREE from 'three';
export const STARTING_CAMERA_POSITION = new THREE.Vector3(0, -.3, 3);

export const MAP_SCALE = 0.01;

export const MAP_PIN_TEXTURE = "/img/nightlife/map_pin.png";
export const MAP_PIN_OFFSET = new THREE.Vector2(0, 0.35);
export const MAP_PIN_SCALE = 0.0005;

export const LIGHT_DATA = [
  {
    type: THREE.AmbientLight,
    color: 0x404040,
    intensity: 1
  },
  {
    type: THREE.DirectionalLight,
    color: 0xffffaa,
    intensity: 1.5,
    position: new THREE.Vector3(6, 4, 10),
    lookAt: new THREE.Vector3(0, 0, 0)
  },
  {
    type: THREE.DirectionalLight,
    color: 0xaaffff,
    intensity: 1.5,
    position: new THREE.Vector3(-4, -6, 10),
    lookAt: new THREE.Vector3(0, 0, 0)
  }
]
